/*@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;700;800&display=swap');*/

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @keyframes scroll {
    0% {
      width: 0;
    }
    70% {
      width: 100%;
      transform: translateX(0);
    }
    100% {
      transform: translateX(100%);
    }
  }

  ul.blue-dots li:after {
    content: "\A";
    width: 3px;
    height: 3px;
    background: #0099ce;
    display: inline-block;
    vertical-align: middle;
  }
  ul.blue-dots li:last-child:after {
    display: none;
  }

  .animate-scroll {
    animation: scroll 2s ease-in-out infinite;
  }

  /* styles for UI */
  .link {
    transition: all 0.5s ease;
  }

  .link:hover {
    transition: all 0.5s ease;
  }

  .link__arrow--right {
    transition: all 0.5s ease;
  }

  /*.link:hover .link__arrow--right {*/
  /*    transform: translateX(10px);*/
  /*    transition: all 0.5s ease;*/
  /*}*/
  /* styles for components */

  /*.member__image {*/
  /*    transition: all 0.3s ease;*/
  /*}*/

  /*.member__image:hover {*/
  /*    transition: all 0.3s ease;*/
  /*    scale: 1.1;*/
  /*    left: -10%;*/
  /*    bottom: 0;*/
  /*}*/

  .member__image {
    height: 325px;
    width: 325px;
  }

  .blue-square-ul ul {
    list-style-type: square;
  }

  .blue-square-ul ul li::marker {
    color: #0099ce;
  }

  @media only screen and (min-width: 769px) {
    .member__image {
      height: 435px;
      width: 435px;
    }
  }

  .logo {
    width: 100%;
  }

  .logo span {
    position: unset !important;
  }

  .logo__image {
    object-fit: contain;
    width: 100% !important;
    position: relative !important;
    height: unset !important;
  }

  /* styles for inclined borders */
  .clip-path-global {
    clip-path: polygon(3% 0%, 100% 0%, 97% 100%, 0% 100%);
  }

  .clip-path-global-negative-margin {
    margin: 0 -3%;
  }

  .clip-path-image {
    clip-path: polygon(0% 0%, 100% 0%, 95% 100%, 0% 100%);
  }

  .clip-path-image-reverse {
    clip-path: polygon(5% 0%, 100% 0%, 100% 100%, 0% 100%);
  }

  .clip-path-button {
    clip-path: polygon(0% 0%, 100% 0%, 97% 100%, 0% 100%);
  }

  .clip-path-cta-text {
    clip-path: polygon(0% 0%, 100% 0%, 95% 100%, 0% 100%);
  }

  .clip-path-cta-text-reverse {
    clip-path: polygon(5% 0%, 100% 0%, 100% 100%, 0% 100%);
  }

  .clip-path-arrow-left {
    clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 0% 100%);
  }

  .clip-path-arrow-right {
    clip-path: polygon(10% 0%, 100% 0%, 100% 100%, 0% 100%);
  }

  .clip-path-services-slider {
    clip-path: polygon(-15% 0%, 115% 0%, 110% 100%, -20% 100%);
  }

  .clip-path-job-card {
    clip-path: polygon(0 0, 100% 0%, 90% 100%, 0% 100%);
  }

  /* styles for grid */
  .row-item:nth-child(3n) {
    margin-left: auto;
  }

  .row-item:nth-child(3n + 1) {
    margin-right: auto;
  }

  @media screen and (max-width: 1024px) {
    .row-item:nth-child(2n) {
      margin-left: auto;
      margin-right: unset;
    }

    .row-item:nth-child(2n + 1) {
      margin-right: auto;
      margin-left: unset;
    }
  }

  @media screen and (max-width: 640px) {
    .row-item:nth-child(2n) {
      margin: 0;
      margin-right: auto;
    }

    .row-item:nth-child(2n + 1) {
      margin: 0;
      margin-right: auto;
    }
  }

  .slideshow .slideshow__bullet {
    width: 12px;
    height: 12px;
    background: #e2effa;
    opacity: 1;

    margin: 0 5px;
  }

  .slideshow .swiper-pagination-bullet-active {
    background: #0099ce;
  }

  .slideshow div.swiper-pagination {
    position: absolute;
    bottom: 20px;
    z-index: 10;
    left: 50%;
    transform: translateX(-50%);
  }

  /* styles for map */
  .popup {
    max-width: none !important;
  }

  .popup .mapboxgl-popup-content {
    padding: 0;
  }

  .popup .mapboxgl-popup-tip {
    display: none;
  }

  .popup--office .mapboxgl-popup-close-button {
    display: none;
  }

  .popup--route .mapboxgl-popup-close-button {
    display: none;
  }

  .popup--route::after {
    display: none;
  }

  .rte-styles ul {
    list-style: none;
    margin-left: 0.5em;
  }
  .rte-styles ul li::before {
    content: "\A";
    width: 5px;
    height: 5px;
    background: #0099ce;
    display: inline-block;
    vertical-align: middle;
    margin-left: calc(-1em - 5px);
    margin-right: calc(1em - 5px);
  }
}

/* Hubspot/cookie fix */
#hubspot-messages-iframe-container {
  z-index: 9998 !important;
}
